<template>
  <form @submit="login" :validation-schema="formSchema" class="py-2">
    <div v-if="isOffline" class="mb-4 p-2 bg-yellow-100 dark:bg-yellow-900 rounded-md">
      <p class="text-sm text-yellow-800 dark:text-yellow-200">
        You appear to be offline. Login requires an internet connection.
      </p>
    </div>

    <FormField v-slot="{ componentField }" name="email">
      <FormItem v-auto-animate>
        <!-- <FormLabel>Email</FormLabel> -->
        <FormControl>
          <Input ref="username" v-bind="componentField" :disabled="loading" autocomplete="email" type="text"
            placeholder="Email" />
        </FormControl>
        <FormMessage class="font-light ml-3" />
      </FormItem>
    </FormField>

    <FormField v-slot="{ componentField }" name="password">
      <FormItem v-auto-animate class="py-2">
        <!-- <FormLabel>Password</FormLabel> -->
        <FormControl>
          <Input v-bind="componentField" :disabled="loading" autocomplete="current-password"
            :type="showPassword ? 'text' : 'password'" placeholder="Password" />
          <button type="button" @click.stop="() => { showPassword = !showPassword }"
            class="absolute end-0 top-0 min-h-9 items-center" variant="outline" size="icon">
            <span class=" inset-y-0 px-2">
              <icon :name="showPassword ? 'lucide:eye' : 'lucide:eye-off'"
                class="w-6 h-4 text-muted-foreground" />
            </span>
          </button>
        </FormControl>
        <FormMessage class="font-light ml-3" />
      </FormItem>
    </FormField>

    <div v-if="showMfaInput" class="py-2">
      <FormField v-slot="{ componentField }" name="mfaCode">
        <FormItem v-auto-animate>
          <FormControl>
            <Input v-model="mfaCode" :disabled="loading" type="text" placeholder="Enter MFA code" />
          </FormControl>
          <FormMessage class="font-light ml-3" />
        </FormItem>
      </FormField>
      <Button 
        type="button" 
        @click="verifyMfa" 
        :disabled="!mfaCode || isOffline || loading" 
        class="mt-2"
      >
        <Loader2 v-if="loading" class="w-4 h-4 mr-2 animate-spin" />
        {{ isOffline ? 'Offline' : 'Verify MFA Code' }}
      </Button>
    </div>

    <div v-else class="grid grid-cols-1 gap-4 py-3">
      <nuxt-link to="/forgot-password" class="no-underline pb-4">
        <span type="" class="font-medium text-muted-foreground hover:underline underline-offset-4 text-xs">
          Forgot your password?
        </span>
      </nuxt-link>

      <Button 
        type="submit" 
        class="text-[1em] squircle-10" 
        :disabled="errors.email || errors.password || loading || isOffline"
      >
        <Loader2 v-if="loading && usingEmailPassword" class="w-4 h-4 mr-2 animate-spin" />
        {{ isOffline ? 'Offline' : 'Login' }}
      </Button>
      
      <SocialLogin :disabled="isOffline" />

      
    </div>
  </form>
</template>

<script setup lang="ts">
import { useForm } from 'vee-validate'
import { toTypedSchema } from '@vee-validate/zod'
import * as z from 'zod'
import { Button } from '@/components/ui/button'
import { Loader2 } from 'lucide-vue-next'
import { FormField, FormItem, FormControl, FormMessage } from '@/components/ui/form'
import { Input } from '@/components/ui/input'
import { useFocus } from '@vueuse/core'
import SocialLogin from './SocialLogin.vue'
import { vAutoAnimate } from '@formkit/auto-animate'
import { useOnline } from '@vueuse/core'

const username = ref()
const { focused } = useFocus(username, { initialValue: true })

const client = useSupabaseClient()
const loading = ref(false)
const usingEmailPassword = ref(false)
const showPassword = ref(false)

const showMfaInput = ref(false)
const factorId = ref<string | null>(null)
const mfaCode = ref('')

const formSchema = toTypedSchema(z.object({
  email: z.string().email(),
  password: z.string().min(8),
}))

const apiErrors = ref({
  wrongCredentials: false,
  accountNotVerified: false,
  accountSuspended: false,
  mfaRequired: false
})

const apiErrorMessages = computed(() => {
  return {
    email: apiErrors.value.wrongCredentials ? 'Incorrect username or password' :
      apiErrors.value.accountSuspended ? 'Account suspended' : '',
    password: apiErrors.value.accountNotVerified ? 'Account not verified' : ''
  };
});

const { validate, errors, resetForm, handleSubmit, setErrors } = useForm({
  validationSchema: formSchema
})

const route = useRoute()

const online = useOnline()
const isOffline = computed(() => !online.value)

interface ConfirmResponse {
  status: number;
  data?: {
    allowed: boolean;
  };
  error?: string;
}

const login = handleSubmit(async (values) => {
  if (isOffline.value) {
    return
  }

  usingEmailPassword.value = true
  loading.value = true
  
  try {
    const validator = await validate()
    if (validator.valid) {
      const { data, error } = await client.auth.signInWithPassword({
        email: values.email,
        password: values.password
      })

      if (error) {
        handleLoginError(error)
      } else {
        await handleLoginSuccess()
      }
    }
  } catch (err) {
    loading.value = false
    setErrors({ 
      email: 'Network error occurred. Please check your connection.' 
    })
  } finally {
    loading.value = false
  }
})

const verifyMfa = async () => {
  if (isOffline.value) {
    return
  }

  loading.value = true
  try {
    const { data, error } = await client.auth.verifyOtp({
      factorId: factorId.value!,
      type: 'totp',
      token: mfaCode.value
    })

    if (error) {
      setErrors({ mfaCode: error.message })
    } else {
      await navigateTo('/dashboard')
    }
  } catch (err) {
    setErrors({ 
      mfaCode: 'Network error occurred. Please check your connection.'
    })
  } finally {
    loading.value = false
  }
}

function handleLoginError(error: any) {
  if (error.status === 400 && error.message === 'MFA required') {
    factorId.value = error.data?.factorId
    showMfaInput.value = true
  } else {
    apiErrors.value.wrongCredentials = error.message === "Invalid login credentials"
    if (apiErrors.value.wrongCredentials || 
        apiErrors.value.accountNotVerified || 
        apiErrors.value.accountSuspended) {
      setErrors({
        email: apiErrorMessages.value.email,
        password: apiErrorMessages.value.password
      })
    }
  }
}

async function handleLoginSuccess() {
  try {
    const { data: responseData, error } = await useFetch<ConfirmResponse>('/api/users/confirm', { 
      method: 'POST',
      retry: 1
    })

    if (error.value) {
      console.error('Error checking access:', error.value);
      setErrors({ email: 'Network error occurred. Please check your connection.' });
      return;
    }

    const data = responseData.value;
    if (!data) {
      console.error('No data received from access check');
      setErrors({ email: 'Network error occurred. Please check your connection.' });
      return;
    }

    if (data.error) {
      console.error('API error:', data.error);
      setErrors({ email: 'Access denied. Please contact support.' });
      return;
    }

    if (!data.data?.allowed) {
      setErrors({ email: 'Access not granted. Please wait for approval.' });
      return;
    }

    // Only navigate if access is granted
    await navigateTo('/dashboard');
  } catch (err) {
    console.error('Unexpected error checking access:', err);
    setErrors({ email: 'Network error occurred. Please check your connection.' });
  }
}
</script>